import React, {useEffect, useState} from 'react';
import {createContext} from "react";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import { useLocation } from 'react-router-dom'
import axios from "axios";

export const FormContext = createContext(null);

export function FormContextProvider ({children}) {


    const {pathname} = useLocation();


    let formPathType = '';
    if (pathname.includes('vfr')){
        formPathType = 'vfrRT';
    }
    else if (pathname.includes('ifr')){
        formPathType = 'ifrRT';
    }
    else if (pathname.includes('refresher')){
        formPathType = 'refresherRT';
    }
    else if (pathname.includes('lpe-exam')){
        formPathType = 'lpeExam';
    }
    else if (pathname.includes('lpe-preparation-course')){
        formPathType = 'lpePrepCourse';
    }
    else if (pathname.includes('lpe-placement-test')){
        formPathType = 'lpePlacementTest';
    }


    const { t : translate } = useTranslation();
    const { language : i18lang } = useTranslation().i18n;



    const inPutFormConstructor = (formType) => {

        if (formType == 'vfrRT'){
            return [{
                step : 1,
                title : 'Step 1',
                type  : 'fields',
                columns : [{
                    type : 'personalFields',
                    title : translate('personalFields'),
                    fields : [
                        {
                            name : "firstName",
                            prettyName : translate('firstName'),
                            type : "text",
                            required : "Please fill in your firstname",
                            validate : ""
                        },
                        {
                            name: "lastName",
                            prettyName: translate('lastName'),
                            type: "text",
                            required: "Please fill in your lastname",
                            validate : ""
                        },
                        {
                            name: "dob",
                            prettyName: translate('dob'),
                            type: "date",
                            required: "Please fill in your Date of Birth",
                            validate : ""
                        },
                        {
                            name: "phone",
                            prettyName: translate('phone'),
                            type: "text",
                            required: "Please fill in your phonenumber",
                            validate : ""
                        },
                        {
                            name: "email",
                            prettyName: translate('Email'),
                            type: "text",
                            required: "Please fill in your E-mail",
                            validate: (value) => value.includes('@')
                        }]
                },
                    {
                        type : 'adressFields',
                        title : translate('addressFields'),
                        fields : [
                            {
                                name: "address",
                                prettyName: translate('address'),
                                type: "text",
                                required: "Please fill in your address",
                                validate : ""
                            },
                            {
                                name: "postcode",
                                prettyName: translate('zipCode'),
                                type: "text",
                                required: "Please fill in  your postcode",
                                validate : ""
                            },
                            {
                                name: "city",
                                prettyName: translate('city'),
                                type: "text",
                                required: "Please fill in your city",
                                validate : ""
                            },
                            {
                                name: "country",
                                prettyName: translate('country'),
                                type: "select",
                                required: "",
                                validate : "",
                                options : [
                                    {
                                        name : "Netherlands",
                                        value : "Netherlands"
                                    },
                                    {
                                        name : "United Kingdom",
                                        value : "United Kingdom"
                                    },
                                    {
                                        name : "Germany",
                                        value : "Germany"
                                    },
                                    {
                                        name : "France",
                                        value : "France"
                                    },
                                    {
                                        name : "Italy",
                                        value : "Italy"
                                    },
                                    {
                                        name : "Spain",
                                        value : "Spain"
                                    },
                                    {
                                        name : "Ukraine",
                                        value : "Ukraine"
                                    },
                                    {
                                        name : "Poland",
                                        value : "Poland"
                                    },
                                    {
                                        name : "Romania",
                                        value : "Romania"
                                    },
                                    {
                                        name : "Belgium",
                                        value : "Belgium"
                                    },
                                    {
                                        name : "Czech Republic",
                                        value : "Czech Republic"
                                    },
                                    {
                                        name : "Greece",
                                        value : "Greece"
                                    },
                                    {
                                        name : "Portugal",
                                        value : "Portugal"
                                    },
                                    {
                                        name : "Sweden",
                                        value : "Sweden"
                                    },
                                    {
                                        name : "Hungary",
                                        value : "Hungary"
                                    },
                                    {
                                        name : "Belarus",
                                        value : "Belarus"
                                    },
                                    {
                                        name : "Austria",
                                        value : "Austria"
                                    },
                                    {
                                        name : "Serbia",
                                        value : "Serbia"
                                    },
                                    {
                                        name : "Switzerland",
                                        value : "Switzerland"
                                    },
                                    {
                                        name : "Bulgaria",
                                        value : "Bulgaria"
                                    },
                                    {
                                        name : "Denmark",
                                        value : "Denmark"
                                    },
                                    {
                                        name : "Finland",
                                        value : "Finland"
                                    },
                                    {
                                        name : "Slovakia",
                                        value : "Slovakia"
                                    },
                                    {
                                        name : "Norway",
                                        value : "Norway"
                                    },
                                    {
                                        name : "Ireland",
                                        value : "Ireland"
                                    },
                                    {
                                        name : "Croatia",
                                        value : "Croatia"
                                    },
                                    {
                                        name : "Moldova",
                                        value : "Moldova"
                                    },
                                    {
                                        name : "Bosnia and Herzegovina",
                                        value : "Bosnia and Herzegovina"
                                    },
                                    {
                                        name : "Albania",
                                        value : "Albania"
                                    },
                                    {
                                        name : "Lithuania",
                                        value : "Lithuania"
                                    },
                                    {
                                        name : "North Macedonia",
                                        value : "North Macedonia"
                                    },
                                    {
                                        name : "Slovenia",
                                        value : "Slovenia"
                                    },
                                    {
                                        name : "Latvia",
                                        value : "Latvia"
                                    },
                                    {
                                        name : "Estonia",
                                        value : "Estonia"
                                    },
                                    {
                                        name : "Montenegro",
                                        value : "Montenegro"
                                    },
                                    {
                                        name : "Luxembourg",
                                        value : "Luxembourg"
                                    },
                                    {
                                        name : "Malta",
                                        value : "Malta"
                                    },
                                    {
                                        name : "Iceland",
                                        value : "Iceland"
                                    },
                                    {
                                        name : "Andorra",
                                        value : "Andorra"
                                    },
                                    {
                                        name : "Monaco",
                                        value : "Monaco"
                                    },
                                    {
                                        name : "Liechtenstein",
                                        value : "Liechtenstein"
                                    },
                                    {
                                        name : "San Marino",
                                        value : "San Marino"
                                    }
                                ]
                            },{
                                name: "course-date",
                                prettyName: translate('courseDate'),
                                type: "course-date",
                                required: "Please choose a course date",
                                validate: (value) => value.includes(1)
                            },]
                    }]
            },
                {
                    step: 2,
                    title : 'Step 2',
                    type : 'overview',
                    columns : []

                }];
        }
        if (formType == 'ifrRT'){
            return [{
                step : 1,
                title : 'Step 1',
                type  : 'fields',
                columns : [{
                    type : 'personalFields',
                    title : translate('personalFields'),
                    fields : [
                        {
                            name : "firstName",
                            prettyName : translate('firstName'),
                            type : "text",
                            required : "Please fill in your firstname",
                            validate : ""
                        },
                        {
                            name: "lastName",
                            prettyName: translate('lastName'),
                            type: "text",
                            required: "Please fill in your lastname",
                            validate : ""
                        },
                        {
                            name: "dob",
                            prettyName: translate('dob'),
                            type: "date",
                            required: "Please fill in your Date of Birth",
                            validate : ""
                        },
                        {
                            name: "phone",
                            prettyName: translate('phone'),
                            type: "text",
                            required: "Please fill in your phonenumber",
                            validate : ""
                        },
                        {
                            name: "email",
                            prettyName: translate('Email'),
                            type: "text",
                            required: "Please fill in your E-mail",
                            validate: (value) => value.includes('@')
                        }]
                },
                    {
                        type : 'adressFields',
                        title : translate('addressFields'),
                        fields : [
                            {
                                name: "address",
                                prettyName: translate('address'),
                                type: "text",
                                required: "Please fill in your address",
                                validate : ""
                            },
                            {
                                name: "postcode",
                                prettyName: translate('zipCode'),
                                type: "text",
                                required: "Please fill in  your postcode",
                                validate : ""
                            },
                            {
                                name: "city",
                                prettyName: translate('city'),
                                type: "text",
                                required: "Please fill in your city",
                                validate : ""
                            },
                            {
                                name: "country",
                                prettyName: translate('country'),
                                type: "select",
                                required: "",
                                validate : "",
                                options : [
                                    {
                                        name : "Netherlands",
                                        value : "Netherlands"
                                    },
                                    {
                                        name : "United Kingdom",
                                        value : "United Kingdom"
                                    },
                                    {
                                        name : "Germany",
                                        value : "Germany"
                                    },
                                    {
                                        name : "France",
                                        value : "France"
                                    },
                                    {
                                        name : "Italy",
                                        value : "Italy"
                                    },
                                    {
                                        name : "Spain",
                                        value : "Spain"
                                    },
                                    {
                                        name : "Ukraine",
                                        value : "Ukraine"
                                    },
                                    {
                                        name : "Poland",
                                        value : "Poland"
                                    },
                                    {
                                        name : "Romania",
                                        value : "Romania"
                                    },
                                    {
                                        name : "Belgium",
                                        value : "Belgium"
                                    },
                                    {
                                        name : "Czech Republic",
                                        value : "Czech Republic"
                                    },
                                    {
                                        name : "Greece",
                                        value : "Greece"
                                    },
                                    {
                                        name : "Portugal",
                                        value : "Portugal"
                                    },
                                    {
                                        name : "Sweden",
                                        value : "Sweden"
                                    },
                                    {
                                        name : "Hungary",
                                        value : "Hungary"
                                    },
                                    {
                                        name : "Belarus",
                                        value : "Belarus"
                                    },
                                    {
                                        name : "Austria",
                                        value : "Austria"
                                    },
                                    {
                                        name : "Serbia",
                                        value : "Serbia"
                                    },
                                    {
                                        name : "Switzerland",
                                        value : "Switzerland"
                                    },
                                    {
                                        name : "Bulgaria",
                                        value : "Bulgaria"
                                    },
                                    {
                                        name : "Denmark",
                                        value : "Denmark"
                                    },
                                    {
                                        name : "Finland",
                                        value : "Finland"
                                    },
                                    {
                                        name : "Slovakia",
                                        value : "Slovakia"
                                    },
                                    {
                                        name : "Norway",
                                        value : "Norway"
                                    },
                                    {
                                        name : "Ireland",
                                        value : "Ireland"
                                    },
                                    {
                                        name : "Croatia",
                                        value : "Croatia"
                                    },
                                    {
                                        name : "Moldova",
                                        value : "Moldova"
                                    },
                                    {
                                        name : "Bosnia and Herzegovina",
                                        value : "Bosnia and Herzegovina"
                                    },
                                    {
                                        name : "Albania",
                                        value : "Albania"
                                    },
                                    {
                                        name : "Lithuania",
                                        value : "Lithuania"
                                    },
                                    {
                                        name : "North Macedonia",
                                        value : "North Macedonia"
                                    },
                                    {
                                        name : "Slovenia",
                                        value : "Slovenia"
                                    },
                                    {
                                        name : "Latvia",
                                        value : "Latvia"
                                    },
                                    {
                                        name : "Estonia",
                                        value : "Estonia"
                                    },
                                    {
                                        name : "Montenegro",
                                        value : "Montenegro"
                                    },
                                    {
                                        name : "Luxembourg",
                                        value : "Luxembourg"
                                    },
                                    {
                                        name : "Malta",
                                        value : "Malta"
                                    },
                                    {
                                        name : "Iceland",
                                        value : "Iceland"
                                    },
                                    {
                                        name : "Andorra",
                                        value : "Andorra"
                                    },
                                    {
                                        name : "Monaco",
                                        value : "Monaco"
                                    },
                                    {
                                        name : "Liechtenstein",
                                        value : "Liechtenstein"
                                    },
                                    {
                                        name : "San Marino",
                                        value : "San Marino"
                                    }
                                ]
                            },{
                                name: "course-date",
                                prettyName: translate('courseDate'),
                                type: "course-date",
                                required: "Please choose a course date",
                                validate: (value) => value.includes(1)
                            },]
                    }]
            },
                {
                    step: 2,
                    title : 'Step 2',
                    type : 'overview',
                    columns : []

                }];
        }
        if (formType == 'refresherRT'){
            return [{
                step : 1,
                title : 'Step 1',
                type  : 'fields',
                columns : [{
                    type : 'personalFields',
                    title : translate('personalFields'),
                    fields : [
                        {
                            name : "firstName",
                            prettyName : translate('firstName'),
                            type : "text",
                            required : "Please fill in your firstname",
                            validate : ""
                        },
                        {
                            name: "lastName",
                            prettyName: translate('lastName'),
                            type: "text",
                            required: "Please fill in your lastname",
                            validate : ""
                        },
                        {
                            name: "dob",
                            prettyName: translate('dob'),
                            type: "date",
                            required: "Please fill in your Date of Birth",
                            validate : ""
                        },
                        {
                            name: "phone",
                            prettyName: translate('phone'),
                            type: "text",
                            required: "Please fill in your phonenumber",
                            validate : ""
                        },
                        {
                            name: "email",
                            prettyName: translate('Email'),
                            type: "text",
                            required: "Please fill in your E-mail",
                            validate: (value) => value.includes('@')
                        }]
                },
                    {
                        type : 'adressFields',
                        title : translate('addressFields'),
                        fields : [
                            {
                                name: "address",
                                prettyName: translate('address'),
                                type: "text",
                                required: "Please fill in your address",
                                validate : ""
                            },
                            {
                                name: "postcode",
                                prettyName: translate('zipCode'),
                                type: "text",
                                required: "Please fill in  your postcode",
                                validate : ""
                            },
                            {
                                name: "city",
                                prettyName: translate('city'),
                                type: "text",
                                required: "Please fill in your city",
                                validate : ""
                            },
                            {
                                name: "country",
                                prettyName: translate('country'),
                                type: "select",
                                required: "",
                                validate : "",
                                options : [
                                    {
                                        name : "Netherlands",
                                        value : "Netherlands"
                                    },
                                    {
                                        name : "United Kingdom",
                                        value : "United Kingdom"
                                    },
                                    {
                                        name : "Germany",
                                        value : "Germany"
                                    },
                                    {
                                        name : "France",
                                        value : "France"
                                    },
                                    {
                                        name : "Italy",
                                        value : "Italy"
                                    },
                                    {
                                        name : "Spain",
                                        value : "Spain"
                                    },
                                    {
                                        name : "Ukraine",
                                        value : "Ukraine"
                                    },
                                    {
                                        name : "Poland",
                                        value : "Poland"
                                    },
                                    {
                                        name : "Romania",
                                        value : "Romania"
                                    },
                                    {
                                        name : "Belgium",
                                        value : "Belgium"
                                    },
                                    {
                                        name : "Czech Republic",
                                        value : "Czech Republic"
                                    },
                                    {
                                        name : "Greece",
                                        value : "Greece"
                                    },
                                    {
                                        name : "Portugal",
                                        value : "Portugal"
                                    },
                                    {
                                        name : "Sweden",
                                        value : "Sweden"
                                    },
                                    {
                                        name : "Hungary",
                                        value : "Hungary"
                                    },
                                    {
                                        name : "Belarus",
                                        value : "Belarus"
                                    },
                                    {
                                        name : "Austria",
                                        value : "Austria"
                                    },
                                    {
                                        name : "Serbia",
                                        value : "Serbia"
                                    },
                                    {
                                        name : "Switzerland",
                                        value : "Switzerland"
                                    },
                                    {
                                        name : "Bulgaria",
                                        value : "Bulgaria"
                                    },
                                    {
                                        name : "Denmark",
                                        value : "Denmark"
                                    },
                                    {
                                        name : "Finland",
                                        value : "Finland"
                                    },
                                    {
                                        name : "Slovakia",
                                        value : "Slovakia"
                                    },
                                    {
                                        name : "Norway",
                                        value : "Norway"
                                    },
                                    {
                                        name : "Ireland",
                                        value : "Ireland"
                                    },
                                    {
                                        name : "Croatia",
                                        value : "Croatia"
                                    },
                                    {
                                        name : "Moldova",
                                        value : "Moldova"
                                    },
                                    {
                                        name : "Bosnia and Herzegovina",
                                        value : "Bosnia and Herzegovina"
                                    },
                                    {
                                        name : "Albania",
                                        value : "Albania"
                                    },
                                    {
                                        name : "Lithuania",
                                        value : "Lithuania"
                                    },
                                    {
                                        name : "North Macedonia",
                                        value : "North Macedonia"
                                    },
                                    {
                                        name : "Slovenia",
                                        value : "Slovenia"
                                    },
                                    {
                                        name : "Latvia",
                                        value : "Latvia"
                                    },
                                    {
                                        name : "Estonia",
                                        value : "Estonia"
                                    },
                                    {
                                        name : "Montenegro",
                                        value : "Montenegro"
                                    },
                                    {
                                        name : "Luxembourg",
                                        value : "Luxembourg"
                                    },
                                    {
                                        name : "Malta",
                                        value : "Malta"
                                    },
                                    {
                                        name : "Iceland",
                                        value : "Iceland"
                                    },
                                    {
                                        name : "Andorra",
                                        value : "Andorra"
                                    },
                                    {
                                        name : "Monaco",
                                        value : "Monaco"
                                    },
                                    {
                                        name : "Liechtenstein",
                                        value : "Liechtenstein"
                                    },
                                    {
                                        name : "San Marino",
                                        value : "San Marino"
                                    }
                                ]
                            },{
                                name: "course-date",
                                prettyName: translate('courseDate'),
                                type: "course-date",
                                required: "Please choose a course date",
                                validate: (value) => value.includes(1)
                            },]
                    }]
            },
                {
                    step: 2,
                    title : 'Step 2',
                    type : 'overview',
                    columns : []

                }];
        }
        if (formType == 'lpeExam'){
            return [{
                step : 1,
                title : 'Step 1',
                type  : 'fields',
                columns : [{
                    type : 'personalFields',
                    title : translate('personalFields'),
                    fields : [
                        {
                            name : "firstName",
                            prettyName : translate('firstName'),
                            type : "text",
                            required : "Please fill in your firstname",
                            validate : ""
                        },
                        {
                            name: "lastName",
                            prettyName: translate('lastName'),
                            type: "text",
                            required: "Please fill in your lastname",
                            validate : ""
                        },
                        {
                            name: "email",
                            prettyName: translate('Email'),
                            type: "text",
                            required: "Please fill in your E-mail",
                            validate: (value) => value.includes('@')
                        }]
                },
                    {
                        type : 'adressFields',
                        title : translate('addressFields'),
                        fields : [
                            {
                                name: "address",
                                prettyName: translate('address'),
                                type: "text",
                                required: "Please fill in your address",
                                validate : ""
                            },
                            {
                                name: "postcode",
                                prettyName: translate('zipCode'),
                                type: "text",
                                required: "Please fill in  your postcode",
                                validate : ""
                            },
                            {
                                name: "city",
                                prettyName: translate('city'),
                                type: "text",
                                required: "Please fill in your city",
                                validate : ""
                            },
                            {
                                name: "country",
                                prettyName: translate('country'),
                                type: "select",
                                required: "",
                                validate : "",
                                options : [
                                    {
                                        name : "Netherlands",
                                        value : "Netherlands"
                                    },
                                    {
                                        name : "United Kingdom",
                                        value : "United Kingdom"
                                    },
                                    {
                                        name : "Germany",
                                        value : "Germany"
                                    },
                                    {
                                        name : "France",
                                        value : "France"
                                    },
                                    {
                                        name : "Italy",
                                        value : "Italy"
                                    },
                                    {
                                        name : "Spain",
                                        value : "Spain"
                                    },
                                    {
                                        name : "Ukraine",
                                        value : "Ukraine"
                                    },
                                    {
                                        name : "Poland",
                                        value : "Poland"
                                    },
                                    {
                                        name : "Romania",
                                        value : "Romania"
                                    },
                                    {
                                        name : "Belgium",
                                        value : "Belgium"
                                    },
                                    {
                                        name : "Czech Republic",
                                        value : "Czech Republic"
                                    },
                                    {
                                        name : "Greece",
                                        value : "Greece"
                                    },
                                    {
                                        name : "Portugal",
                                        value : "Portugal"
                                    },
                                    {
                                        name : "Sweden",
                                        value : "Sweden"
                                    },
                                    {
                                        name : "Hungary",
                                        value : "Hungary"
                                    },
                                    {
                                        name : "Belarus",
                                        value : "Belarus"
                                    },
                                    {
                                        name : "Austria",
                                        value : "Austria"
                                    },
                                    {
                                        name : "Serbia",
                                        value : "Serbia"
                                    },
                                    {
                                        name : "Switzerland",
                                        value : "Switzerland"
                                    },
                                    {
                                        name : "Bulgaria",
                                        value : "Bulgaria"
                                    },
                                    {
                                        name : "Denmark",
                                        value : "Denmark"
                                    },
                                    {
                                        name : "Finland",
                                        value : "Finland"
                                    },
                                    {
                                        name : "Slovakia",
                                        value : "Slovakia"
                                    },
                                    {
                                        name : "Norway",
                                        value : "Norway"
                                    },
                                    {
                                        name : "Ireland",
                                        value : "Ireland"
                                    },
                                    {
                                        name : "Croatia",
                                        value : "Croatia"
                                    },
                                    {
                                        name : "Moldova",
                                        value : "Moldova"
                                    },
                                    {
                                        name : "Bosnia and Herzegovina",
                                        value : "Bosnia and Herzegovina"
                                    },
                                    {
                                        name : "Albania",
                                        value : "Albania"
                                    },
                                    {
                                        name : "Lithuania",
                                        value : "Lithuania"
                                    },
                                    {
                                        name : "North Macedonia",
                                        value : "North Macedonia"
                                    },
                                    {
                                        name : "Slovenia",
                                        value : "Slovenia"
                                    },
                                    {
                                        name : "Latvia",
                                        value : "Latvia"
                                    },
                                    {
                                        name : "Estonia",
                                        value : "Estonia"
                                    },
                                    {
                                        name : "Montenegro",
                                        value : "Montenegro"
                                    },
                                    {
                                        name : "Luxembourg",
                                        value : "Luxembourg"
                                    },
                                    {
                                        name : "Malta",
                                        value : "Malta"
                                    },
                                    {
                                        name : "Iceland",
                                        value : "Iceland"
                                    },
                                    {
                                        name : "Andorra",
                                        value : "Andorra"
                                    },
                                    {
                                        name : "Monaco",
                                        value : "Monaco"
                                    },
                                    {
                                        name : "Liechtenstein",
                                        value : "Liechtenstein"
                                    },
                                    {
                                        name : "San Marino",
                                        value : "San Marino"
                                    }
                                ]
                            },
                            {
                                name: "lpePlacement",
                                prettyName: translate('lpePlacementTest'),
                                type: "checkbox",
                                descr:translate('lpePlacementTestDescr'),
                                price:50,
                                required: false,
                                validate : ""
                            },
                            {
                                name: "lpePrepCourse",
                                prettyName: translate('lpePrepCourse'),
                                type: "checkbox",
                                descr:translate('lpePrepCourseDescr'),
                                price:100,
                                required: false,
                                validate : ""
                            }
                        ]
                    }]
            },
                {
                    step: 2,
                    title : 'Step 2',
                    type : 'overview',
                    columns : []

                }];
        }
        if (formType == 'lpePrepCourse'){
            return [{
                step : 1,
                title : 'Step 1',
                type  : 'fields',
                columns : [{
                    type : 'personalFields',
                    title : translate('personalFields'),
                    fields : [
                        {
                            name : "firstName",
                            prettyName : translate('firstName'),
                            type : "text",
                            required : "Please fill in your firstname",
                            validate : ""
                        },
                        {
                            name: "lastName",
                            prettyName: translate('lastName'),
                            type: "text",
                            required: "Please fill in your lastname",
                            validate : ""
                        },
                        {
                            name: "email",
                            prettyName: translate('Email'),
                            type: "text",
                            required: "Please fill in your E-mail",
                            validate: (value) => value.includes('@')
                        },
                        {
                            name: "prep-course-date",
                            prettyName: translate('courseDate'),
                            type: "lpe-prep-course-date",
                            required: "Please choose a course date",
                            validate: (value) => value.includes(1)
                        }]
                },
                    {
                        type : 'adressFields',
                        title : translate('addressFields'),
                        fields : [
                            {
                                name: "address",
                                prettyName: translate('address'),
                                type: "text",
                                required: "Please fill in your address",
                                validate : ""
                            },
                            {
                                name: "postcode",
                                prettyName: translate('zipCode'),
                                type: "text",
                                required: "Please fill in  your postcode",
                                validate : ""
                            },
                            {
                                name: "city",
                                prettyName: translate('city'),
                                type: "text",
                                required: "Please fill in your city",
                                validate : ""
                            },
                            {
                                name: "country",
                                prettyName: translate('country'),
                                type: "select",
                                required: "",
                                validate : "",
                                options : [
                                    {
                                        name : "Netherlands",
                                        value : "Netherlands"
                                    },
                                    {
                                        name : "United Kingdom",
                                        value : "United Kingdom"
                                    },
                                    {
                                        name : "Germany",
                                        value : "Germany"
                                    },
                                    {
                                        name : "France",
                                        value : "France"
                                    },
                                    {
                                        name : "Italy",
                                        value : "Italy"
                                    },
                                    {
                                        name : "Spain",
                                        value : "Spain"
                                    },
                                    {
                                        name : "Ukraine",
                                        value : "Ukraine"
                                    },
                                    {
                                        name : "Poland",
                                        value : "Poland"
                                    },
                                    {
                                        name : "Romania",
                                        value : "Romania"
                                    },
                                    {
                                        name : "Belgium",
                                        value : "Belgium"
                                    },
                                    {
                                        name : "Czech Republic",
                                        value : "Czech Republic"
                                    },
                                    {
                                        name : "Greece",
                                        value : "Greece"
                                    },
                                    {
                                        name : "Portugal",
                                        value : "Portugal"
                                    },
                                    {
                                        name : "Sweden",
                                        value : "Sweden"
                                    },
                                    {
                                        name : "Hungary",
                                        value : "Hungary"
                                    },
                                    {
                                        name : "Belarus",
                                        value : "Belarus"
                                    },
                                    {
                                        name : "Austria",
                                        value : "Austria"
                                    },
                                    {
                                        name : "Serbia",
                                        value : "Serbia"
                                    },
                                    {
                                        name : "Switzerland",
                                        value : "Switzerland"
                                    },
                                    {
                                        name : "Bulgaria",
                                        value : "Bulgaria"
                                    },
                                    {
                                        name : "Denmark",
                                        value : "Denmark"
                                    },
                                    {
                                        name : "Finland",
                                        value : "Finland"
                                    },
                                    {
                                        name : "Slovakia",
                                        value : "Slovakia"
                                    },
                                    {
                                        name : "Norway",
                                        value : "Norway"
                                    },
                                    {
                                        name : "Ireland",
                                        value : "Ireland"
                                    },
                                    {
                                        name : "Croatia",
                                        value : "Croatia"
                                    },
                                    {
                                        name : "Moldova",
                                        value : "Moldova"
                                    },
                                    {
                                        name : "Bosnia and Herzegovina",
                                        value : "Bosnia and Herzegovina"
                                    },
                                    {
                                        name : "Albania",
                                        value : "Albania"
                                    },
                                    {
                                        name : "Lithuania",
                                        value : "Lithuania"
                                    },
                                    {
                                        name : "North Macedonia",
                                        value : "North Macedonia"
                                    },
                                    {
                                        name : "Slovenia",
                                        value : "Slovenia"
                                    },
                                    {
                                        name : "Latvia",
                                        value : "Latvia"
                                    },
                                    {
                                        name : "Estonia",
                                        value : "Estonia"
                                    },
                                    {
                                        name : "Montenegro",
                                        value : "Montenegro"
                                    },
                                    {
                                        name : "Luxembourg",
                                        value : "Luxembourg"
                                    },
                                    {
                                        name : "Malta",
                                        value : "Malta"
                                    },
                                    {
                                        name : "Iceland",
                                        value : "Iceland"
                                    },
                                    {
                                        name : "Andorra",
                                        value : "Andorra"
                                    },
                                    {
                                        name : "Monaco",
                                        value : "Monaco"
                                    },
                                    {
                                        name : "Liechtenstein",
                                        value : "Liechtenstein"
                                    },
                                    {
                                        name : "San Marino",
                                        value : "San Marino"
                                    }
                                ]
                            },
                            {
                                name: "lpeExam",
                                prettyName: translate('lpeExam'),
                                type: "checkbox",
                                descr:translate('lpeExamDescr'),
                                price:164,
                                required: false,
                                validate : ""
                            },
                            {
                                name: "lpePlacementTest",
                                prettyName: translate('lpePlacementTest'),
                                type: "checkbox",
                                descr:translate('lpePlacementTestDescr'),
                                price:50,
                                required: false,
                                validate : ""
                            }
                        ]
                    }]
            },
                {
                    step: 2,
                    title : 'Step 2',
                    type : 'overview',
                    columns : []

                }];
        }
        if (formType == 'lpePlacementTest'){
            return [{
                step : 1,
                title : 'Step 1',
                type  : 'fields',
                columns : [{
                    type : 'personalFields',
                    title : translate('personalFields'),
                    fields : [
                        {
                            name : "firstName",
                            prettyName : translate('firstName'),
                            type : "text",
                            required : "Please fill in your firstname",
                            validate : ""
                        },
                        {
                            name: "lastName",
                            prettyName: translate('lastName'),
                            type: "text",
                            required: "Please fill in your lastname",
                            validate : ""
                        },
                        {
                            name: "email",
                            prettyName: translate('Email'),
                            type: "text",
                            required: "Please fill in your E-mail",
                            validate: (value) => value.includes('@')
                        }]
                },
                    {
                        type : 'adressFields',
                        title : translate('addressFields'),
                        fields : [
                            {
                                name: "address",
                                prettyName: translate('address'),
                                type: "text",
                                required: "Please fill in your address",
                                validate : ""
                            },
                            {
                                name: "postcode",
                                prettyName: translate('zipCode'),
                                type: "text",
                                required: "Please fill in  your postcode",
                                validate : ""
                            },
                            {
                                name: "city",
                                prettyName: translate('city'),
                                type: "text",
                                required: "Please fill in your city",
                                validate : ""
                            },
                            {
                                name: "country",
                                prettyName: translate('country'),
                                type: "select",
                                required: "",
                                validate : "",
                                options : [
                                    {
                                        name : "Netherlands",
                                        value : "Netherlands"
                                    },
                                    {
                                        name : "United Kingdom",
                                        value : "United Kingdom"
                                    },
                                    {
                                        name : "Germany",
                                        value : "Germany"
                                    },
                                    {
                                        name : "France",
                                        value : "France"
                                    },
                                    {
                                        name : "Italy",
                                        value : "Italy"
                                    },
                                    {
                                        name : "Spain",
                                        value : "Spain"
                                    },
                                    {
                                        name : "Ukraine",
                                        value : "Ukraine"
                                    },
                                    {
                                        name : "Poland",
                                        value : "Poland"
                                    },
                                    {
                                        name : "Romania",
                                        value : "Romania"
                                    },
                                    {
                                        name : "Belgium",
                                        value : "Belgium"
                                    },
                                    {
                                        name : "Czech Republic",
                                        value : "Czech Republic"
                                    },
                                    {
                                        name : "Greece",
                                        value : "Greece"
                                    },
                                    {
                                        name : "Portugal",
                                        value : "Portugal"
                                    },
                                    {
                                        name : "Sweden",
                                        value : "Sweden"
                                    },
                                    {
                                        name : "Hungary",
                                        value : "Hungary"
                                    },
                                    {
                                        name : "Belarus",
                                        value : "Belarus"
                                    },
                                    {
                                        name : "Austria",
                                        value : "Austria"
                                    },
                                    {
                                        name : "Serbia",
                                        value : "Serbia"
                                    },
                                    {
                                        name : "Switzerland",
                                        value : "Switzerland"
                                    },
                                    {
                                        name : "Bulgaria",
                                        value : "Bulgaria"
                                    },
                                    {
                                        name : "Denmark",
                                        value : "Denmark"
                                    },
                                    {
                                        name : "Finland",
                                        value : "Finland"
                                    },
                                    {
                                        name : "Slovakia",
                                        value : "Slovakia"
                                    },
                                    {
                                        name : "Norway",
                                        value : "Norway"
                                    },
                                    {
                                        name : "Ireland",
                                        value : "Ireland"
                                    },
                                    {
                                        name : "Croatia",
                                        value : "Croatia"
                                    },
                                    {
                                        name : "Moldova",
                                        value : "Moldova"
                                    },
                                    {
                                        name : "Bosnia and Herzegovina",
                                        value : "Bosnia and Herzegovina"
                                    },
                                    {
                                        name : "Albania",
                                        value : "Albania"
                                    },
                                    {
                                        name : "Lithuania",
                                        value : "Lithuania"
                                    },
                                    {
                                        name : "North Macedonia",
                                        value : "North Macedonia"
                                    },
                                    {
                                        name : "Slovenia",
                                        value : "Slovenia"
                                    },
                                    {
                                        name : "Latvia",
                                        value : "Latvia"
                                    },
                                    {
                                        name : "Estonia",
                                        value : "Estonia"
                                    },
                                    {
                                        name : "Montenegro",
                                        value : "Montenegro"
                                    },
                                    {
                                        name : "Luxembourg",
                                        value : "Luxembourg"
                                    },
                                    {
                                        name : "Malta",
                                        value : "Malta"
                                    },
                                    {
                                        name : "Iceland",
                                        value : "Iceland"
                                    },
                                    {
                                        name : "Andorra",
                                        value : "Andorra"
                                    },
                                    {
                                        name : "Monaco",
                                        value : "Monaco"
                                    },
                                    {
                                        name : "Liechtenstein",
                                        value : "Liechtenstein"
                                    },
                                    {
                                        name : "San Marino",
                                        value : "San Marino"
                                    }
                                ]
                            },
                            {
                                name: "lpePrepCourse",
                                prettyName: translate('lpePrepCourse'),
                                type: "checkbox",
                                descr:translate('lpePrepCourseDescr'),
                                price:100,
                                required: false,
                                validate : ""
                            },
                            {
                                name: "lpeExam",
                                prettyName: translate('lpeExam'),
                                type: "checkbox",
                                descr:translate('lpeExamDescr'),
                                price:174,
                                required: false,
                                validate : ""
                            }
                        ]
                    }]
            },
                {
                    step: 2,
                    title : 'Step 2',
                    type : 'overview',
                    columns : []

                }];
        }

    }

    const vfrRTPrices = [
        {
            name: 'course',
            price:440
        },
        {
            name: 'book',
            price:15
        },
        {
            name: 'exam',
            price:100
        }
    ]
    const ifrRTPrices = [
        {
            name: 'course',
            price:367
        },
        {
            name: 'book',
            price:15
        },
        {
            name: 'exam',
            price:100
        }

    ]
    const refresherRTPrices = [
        {
            name: 'course',
            price:165
        }
    ]
    const lpePrices = [
        {
            name: 'LPE Exam',
            price:174
        }
    ]
    const lpePrepPrices = [
        {
            name: 'LPE Preparation Course',
            price:100
        }
    ]
    const lpePlacementTestPrices = [
        {
            name: 'LPE Placement Test',
            price:50
        }
    ]



    const { t } = useTranslation();
    const [formType,setFormType] = useState(formPathType);
    const [currentStep, setCurrentStep] = useState({step:1,title:'Overview'});
    const [context, setContext] = useState({});
    const [step1Content, setStep1Content] = useState({});
    const [step2Content, setStep2Content] = useState({});
    const [completeForm,setCompleteForm] = useState(inPutFormConstructor(formType));
    const [completeContent, setCompleteContent] = useState();
    const [selectedLanguage, setSelectedLanguage] = useState(i18lang);
    const [rerender, setRerender] = useState(0);
    const [priceList, setPriceList] = useState([]);
    const [priceTotal,setPriceTotal] = useState();


    const switchLang = async () => {


        try {
            const update = await i18next.changeLanguage(selectedLanguage);
            setCompleteForm(inPutFormConstructor(formPathType));
        }
        catch (e) {
            console.log(e)
        }
    }

    useEffect(()=> {
        let prices = [];
        if (formType === 'vfrRT'){
            prices = vfrRTPrices;
        }
        else if (formType ===  'ifrRT') {
            prices= ifrRTPrices;
        }
        else if (formType ===  'refresherRT') {
            prices= refresherRTPrices;
        }
        else if (formType ===  'lpeExam') {
            prices= lpePrices;
        }
        else if (formType ===  'lpePrepCourse') {
            prices= lpePrepPrices;
        }
        else if (formType ===  'lpePlacementTest') {
            prices= lpePlacementTestPrices;
        }

        let total = 0;

        calcTotal(prices);


        setPriceList([
            ...prices
        ]);


    },[])



    useEffect(()=> {
        switchLang();
    },[selectedLanguage])

    useEffect(()=> {

        const columns = completeForm[0].columns;
        const fields = completeForm[0].columns[0].fields;

        let newFields = [];

        columns.map((column)=>{

            column.fields.map((field) => {
                const newField = {
                    ...field,
                    value: step1Content[field.name]
                }
                newFields.push(newField);
            })

        })

        //If prep course date is filled in the lpe-exam form, then add it to the newfields to post to the backend
        if(step1Content['prep-course-date']){
            newFields.push({'name':'prep-course-date','value':step1Content['prep-course-date']});
        }
        

        setCompleteContent({
            ...completeContent,
            newFields
        })

    },[step1Content]);

    useEffect(()=> {
        setCompleteForm({
                ...completeForm,
                step2Content
        })
    },[step2Content]);

    useEffect(()=>{
        if (currentStep.step === 3){
            saveForm();

        }
    },[currentStep]);

    useEffect(()=>{
        calcTotal(priceList);
    },[priceList])



    const saveForm = async () => {

        try {
            const postObject = {
                formType:formType,
                selectedLanguage:selectedLanguage,
                ...completeContent,
                priceList:priceList,
                priceTotal:priceTotal
            }

            const result = await axios.post('https://www.atc-comm.com/api/post-form.php',
                postObject, {
                    'Content-Type': 'application/json'
                });
        }
        catch (e) {
            console.log(e)
        }
    }


    const stepForward = () => {
        setCurrentStep({
            ...currentStep,
            step:currentStep.step+1
        })

       /* saveForm();*/
    }

    const stepBack = () => {


        setCurrentStep({
            ...currentStep,
            step:currentStep.step-1
        })
    }

    const rerenderForm = () => {

        setCompleteForm(inPutFormConstructor(formType));
    }

    const addToPriceList = (name,price) => {

        let exists = false;
        for (let i = 0; i < priceList.length; i++) {
            if (priceList[i].name === name){
                exists = true;
            }
        }

        if (!exists){

            setPriceList([
                ...priceList,
                {
                    name: name,
                    price: price
                },

            ])
        }
    }

    const removeFromPriceList = (name) => {

        const newPriceList = [
            ...priceList,
            ]

        for (let i = 0; i < priceList.length; i++) {
            if (priceList[i].name === name){
                newPriceList.splice(i,1)
            }
        }

        setPriceList([...newPriceList]);


    }

    const calcTotal = (priceListToCalc) => {

        let total = 0;
        for (let i = 0; i < priceListToCalc.length; i++) {



            if (priceListToCalc[i].name === 'total'){
                priceListToCalc.splice(i, 1);
            }
            else {
                total = total + priceListToCalc[i].price;
            }
        }

        setPriceTotal(total);

    }



    const data = {
        currentStep : currentStep,
        ...context,
        step1Content: {...step1Content},
        step2Content:{...step2Content},
        completeForm:{...completeForm},
        stepForward:stepForward,
        stepBack:stepBack,
        setStep1Content:setStep1Content,
        setStep2Content:setStep2Content,
        completeContent:completeContent,
        selectedLanguage:selectedLanguage,
        setSelectedLanguage:setSelectedLanguage,
        rerenderForm:rerenderForm,
        formType:formType,
        saveForm:saveForm,
        priceTotal:priceTotal,
        priceList:priceList,
        addToPriceList:addToPriceList,
        removeFromPriceList:removeFromPriceList

    }



    return  (
        <FormContext.Provider value={data}>
            {children}
        </FormContext.Provider>
    )
}

export default FormContextProvider;